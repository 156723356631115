module.exports = [{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":700}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-autolink-headers"]},
    },{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-56G6NL6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Badmintons Best","short_name":"BadmintonsBest","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"content/assets/badmintonsbest-logo.jpg"},
    },{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/ramielltd/Documents/GitHub/BadmintonsBest/BadmintonsBest/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
